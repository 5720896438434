.about {
    border: 3px solid $color_primary;
    border-radius: 20px;
    padding: 60px;
    display: flex;
    align-items: center;
    gap: 130px;
    font-size: 16px;
    @include screen($tablet_l) {
        gap: 50px;
        padding: 30px;
        align-items: flex-start;
    }
    @include screen($tablet_m) {
        flex-direction: column;
        gap: 0;
    }
    @include screen($mobile) {
        padding: 22px;
        font-size: 14px;
    }
    &__img {
        flex-shrink: 0;
        @include screen($tablet_l) {
            width: calc(50% - 25px);
        }
        @include screen($tablet_m) {
            width: fit-content;
            margin-inline: auto;
        }
    }
    &__text {
        @include screen($tablet_m) {
            display: contents;
        }
    }
    .mark {
        color: #6D6D6D;
        max-width: 90%;
        @include min-screen($tablet_l + 1px) {
            width: 400px;
            max-width: 100%;
        }
        @include screen($tablet_m) {
            order: -1;
          
        }
        @include screen($mobile) {
            font-size: 16px;
        }
    }
    h2 {
        @include screen($tablet_m) {
            order: -2;
            max-width: 90%;
        }
    }
    ul {
        margin-top: 30px;
    }
}