.header {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px;
        padding-top: 48px;
        padding-bottom: 32px;
        @include screen($tablet) {
            padding-top: 8px;
            padding-bottom: 20px;
        }
    }

}