.mini-cart {
    position: relative;
    margin-right: 37px;
    @include screen($tablet_l) {
        margin: 15px 80px 0 0;
    }
    @include screen($mobile) {
        margin: 15px 50px 0 0;
    }
    .num {
        background-color: $color_primary;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: inline-block;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        position: absolute;
        top: -3px;
        right: -3px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include screen($mobile) {
            font-size: 9px;
            width: 15px;
            height: 15px;
        }
    }
    .icon {
        display: block;
        @include screen($mobile) {
            width: 32px;
            height: 32px;
        }
    }
}