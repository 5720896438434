:root {
    --primary-text: #171717;
    --secondary-text: #767676;
    --primary: #7A972D;
    --primary-darked:#5A6F24;
  }
// $burgerColor: #000;
// $burgerColorActive: #fff;



/************************************
* Sizez & Spaces
************************************/
$container: 1440px;

$tablet_l: 1280px;
$tablet_m: 950px;
$tablet_f: 900px;
$tablet: 768px;

$mobile_l:650px;
$mobile:468px;

/************************************
* Text styles
************************************/

//font-family
// $font-main : 'Lato',sans-serif;
// $font-accent: 'Oswald', sans-serif;

// //Text size
// $fs_s: .7rem;
// $fs_m: 1rem;
// $fs_l: 2rem;

// //Header size
// $fs_header_1: 3rem;
// $fs_header_2: 2rem;
// $fs_header_3: 1rem;

/************************************
* Colors
************************************/
$color_primary:var(--primary);
$color_primary_darked:var(--primary-darked);
$color_primary_text: var(--primary-text);
$color_secondary_text: var(--secondary-text);

// /************************************
// * Radius
// ************************************/
// $radius_m : 1rem;

// /************************************
// * Shadows
// ************************************/
// $shadow: 0 0 10px rgba(0,0,0,0.5);

/************************************
* Animation
************************************/
$transition: .2s ease;