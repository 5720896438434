.reviews {
    position: relative;
    .item {
        flex-shrink: 0;
    }
    .sw-pagination {
        position: absolute;
        width: 108px;
        right: 2px;
        top: -6px;
        display: flex;
        justify-content: space-between;
        @include screen($mobile_l) {
            width: 90px;
        }
        .sw-prev, .sw-next {
            position: initial;
            width: 48px;
            height: 48px;
            border: 1px solid #676767;
            background-size: 12px;
            @include screen($mobile_l) {
                width: 40px;
                height: 40px;
            }
        }
    }
    .swiper {
        margin-top: 45px;
    }
}