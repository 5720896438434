.cards-container {
    h2 {
        @include min-screen($tablet + 1px) {
            text-align: center;
        }
        
    }
}
.cards {
    display: flex;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    .card:nth-child(1) {background-color: hsla(349, 71%, 54%, 0.12);}
    .card:nth-child(2) {background-color:#FEF6E9}
    .card:nth-child(3) {background-color: #F3F9FE;}

    @include screen($tablet_m) {
        flex-direction: column;
        margin-top: 27px;
        margin-bottom: 8px;
        gap: 8px;
    }
}