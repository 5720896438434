/*************************
*Блок
*************************/
.radio-group {
	display: flex;
    flex-direction: column;
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
