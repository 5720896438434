/*************************
*Лого с фоном
*************************/
.logo {
    display: inline-block;
    height: 69px;
    width: 75px;
    margin-left: 4px;
    background: url(../img/logo.png) center no-repeat;
    background-size: contain;
    text-indent: -1000px;
    overflow: hidden;
    @media (min-resolution: 2dppx) {
        background-image: url(../img/logo2x.png);
        background-size: contain;
    }
    @include screen($mobile) {
        width: 39px;
        height: 36px;
    }

}