.promo-code {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
    @include screen($mobile_l) {
        margin-top: 24px;
    }
    .btn {
        height: 52px;
        min-width: 188px;
        font-size: 16px;
        @include screen($mobile) {
            width: auto;
        }
    }
    input {
        width: 296px;
    }
}