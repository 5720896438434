/*************************
*Блок
*************************/
.btn {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;

	padding: 23px 24px;
	background-color: $color_primary;
	color: #fff;
	font-size: 1rem;
	border-radius: 12px;
	min-width: 225px;
	width: fit-content;
	text-decoration: none;

	transition: background $transition;

	@include screen($mobile) {
		width: 100%;
		padding-block: 18px;
	}
	&:hover {
		text-decoration: none;
		background-color: $color_primary_darked;
	}
	.icon {
		// width: 24px;//не используетеся
		// height: 24px;
		@include screen($mobile_l) {
			width: 16px;
			height: 16px;
		}
	}
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
.btn {
	&_center {
		display: flex;
		margin-inline: auto;
	}
	&_transp {
		background-color: transparent;
		border:1px solid $color_primary;
		border-radius: 8px;
		color: $color_primary;
		&:hover {
			color: #fff;
		}
	}
	&_grey {
		background-color: #E9E9E9;
		color: $color_primary_text;
		&:hover {
			background-color: #b9b7b7;
		}
	}
}
