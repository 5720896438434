.hero_reverse {
    margin-bottom: 112px;
    @include screen($tablet_m) {
        margin-bottom: 66px;
    }
    .hero {
        &__text {
            order: -1;
            width: 750px;
            padding-top: 38px;
            h1 {
                line-height: 1.2;
            }
        }
        &__img {
            margin-inline: auto;
            @include screen($mobile) {
                width: 230px;
            }
        }
    }
}