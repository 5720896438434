.cart-container {
    position: fixed;
    background: #fff;
    width: 557px;
    max-width: 92%;
    top: 0;
    right: -100%;
    bottom: 0;
    left: auto;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 29px 24px 33px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items:normal;
    // font-size: 28px;
    transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
    @include screen($mobile_l) {
        padding: 18px 18px 18px 16px;
    }
    &.opened {
        right: 0;
    }
}

.mini-cart_close {
    // position: absolute;
    // right: 19px;
    // top: 37px;
    width: 24px;
    height: 24px;
    @include screen($mobile_l) {
        width: 18px;
        height: 18px;
    }
    &::before {
        @extend %ba;
        @extend %burger;
        transform: rotate(45deg);
    }

    &::after {
        @extend %ba;
        @extend %burger;
        transform: rotate(-45deg);
        top: -1px;
    }
}