html {
	font-size: 18px;
	@include screen($tablet) {
		font-size: 14px;
	}
}
body {
	font-family: 'Inter',sans-serif;
 	font-size: 1rem;
	font-style:normal;
	font-weight: 400;
	line-height: 1.21;
	color: $color_primary_text;
	letter-spacing: -.2px;

	background-color: #fff;

	@extend %scrollbar;

	&.no-scroll {
		overflow: hidden;
	}
}

a {
	color: $color_primary;
}

p {
	margin-bottom: .89em;
}

h1 {
	font-weight: 600;
	font-size: 40px;
	margin-bottom: 18px;
	text-transform: uppercase;
	color: $color_primary_text;
	letter-spacing: -.8px;
	line-height: 1.45;
	@include screen($mobile) {
		font-size: 24px;
		line-height: 32px;
    	letter-spacing: -.5px;
	}
}
h2 {
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 18px;
	letter-spacing: -.8px;
	@include screen($mobile) {
		font-size: 25px;
		// line-height: 32px;
    	// letter-spacing: -.5px;
	}
}
h3 {
	font-weight: 500;
	font-size: 28px;
	margin-bottom: 18px;
}
h1, h2, h3 {
	b {
		color: $color_primary;
		font-weight: inherit;
	}
}

.container {
	max-width: $container;
	padding-left: 60px;
	padding-right: 60px;
	margin: 0 auto;
	@include screen($tablet) {
		padding-inline: 16px;
	}
}

label.error {
	color: red;
	input {
		border-color: red;
	}
}
.section {
	margin-bottom: 120px;
	@include screen($mobile_l) {
		margin-bottom: 64px;
	}
}
input, textarea, select {
	max-width: 100%;
	padding: 15px 16px;
	border: 1px solid #f8f8f8;
	border-radius: 8px;
	color: $color_primary_text;
	background-color: #F8F8F8;
	font-size: 16px;
	letter-spacing: 0;

	&::placeholder {
		color:#7E8593;
	}

	&:focus, &:focus-visible {
		border-color: $color_primary;outline: none;
	}
}
select {
	border-radius: 8px;
	border: 1px solid #f8f8f8;
	height: 52px;
	// width: 300px;
    // padding: 10px 20px;
    color: $color_secondary_text;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: url(../img/chevron-down.svg);
	background-position: right 15px center;
	background-repeat:  no-repeat;
	padding-right: 40px;
}

// ol:not([class]) {
//     list-style-type: none;
//     counter-reset: num;
//     margin-bottom: 12px;
//     li {
//         position: relative;
//         padding: 0 0 0.6em 1.5em;

//         &:before {
//             content: counter(num) ".";
//             counter-increment: num;
//             display: block;
//             position: absolute;
//             top: 0;
//             left: -5px;
//             width: 1.25rem;
//             text-align: right;
//         }
//     }
// }
ul:not([class]) {
	list-style-type: none;
	margin-bottom: 12px;
	li {
		position: relative;
		padding: 0 0 1em 1.5em;
		&:before {
			content: '';
			display: block;
            position: absolute;
            top: 0.5em;
    		left: 5px;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background: currentColor;
		}
	}
}