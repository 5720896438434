/*************************
*Блок
*************************/
.radio-group__title {
	font-size: 2rem;
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
