.cart-products {
    margin-bottom: 23px;
    .item {
        padding-block: 15px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        @include screen($mobile) {
            gap: 0 12px;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        &__img {
            flex-shrink: 0;
            border: 1px solid #DEDEDE;
            border-radius: 12px;
            overflow: hidden;
            @include screen($mobile) {
                border-radius: 8px;
                width: 48px;
            }
        }
        &__text {
            flex-shrink: 0;
            flex: 1;
            // width: 32%;
            font-size: 12px;
            @include screen($mobile) {
                flex-basis: calc(100% - 110px);
            }
        }
        &__title {
            color: $color_primary_text;
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 8px;
            @include screen($mobile) {
                font-size: 14px;
                
            }
        }
        &__count {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            width: 60px;
            @include screen($mobile) {
                order: 2;
                margin-left: 60px;
            }
            button {
                width: 16px;
                height: 16px;
                background: url(../img/minus-round.svg) center no-repeat;
                background-size: contain;

                &.plus {
                    background-image: url(../img/plus-round.svg);
                }
            }
            input {
                font-weight: 500;
                padding: 0;
                background-color: transparent;
                border: none;
                outline: none;
                width: 30px;
                text-align: center;
            }
        }
        &__price {
            width: 85px;
            text-align: right;
            flex-shrink: 0;
            white-space: nowrap;
            font-size: 16px;
            @include screen($mobile) {
                order: 3;
                margin-left: 8px;
                margin-right: auto;
                text-align: left;
            }
        }
        &__remove {
            flex-shrink: 0;
            width: 33px;
            text-align: right;
            button {
                width: 16px;
                height: 16px;
                background: url(../img/plus-round.svg) center no-repeat;
                background-size: contain;
                transform: rotate(45deg);
            }
            
        }
    }
}