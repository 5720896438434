.p-card {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 315px;
    border: 1px solid #DCDCDC;
    border-radius: 20px;
    padding: 8px 20px 20px;
    @include screen($mobile) {
        padding-top: 0;
        border-radius: 8px;
    }
    picture {
        width: 100%;
        display: block;text-align: center;
    }

    .title {
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9E9E9;
        padding-bottom: 16px;
        margin-bottom: 20px;
        @include screen($tablet){
            font-size: 18px;
            font-weight: 500;
            margin-top: 13px;
        }
        @include screen($mobile) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }
    .more {
        font-size: 12px;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        // @include screen($tablet){
        //     display: none;
        // }
    }
    .select {
        font-size: 14px;
        color: rgb(118 118 118 / 50%);
        margin-top: auto;
    }
    select {
        width: 100%;
        padding: 8px 30px 8px 10px;
        background-color: #fff;
        border: 1px solid #E0E0E0;
        font-size: 14px;
        height: auto;
        color: $color_primary_text;
        margin-top: 9px;
        margin-bottom: 30px;
        background-size: 18px;
        background-position: right 8px center;
    }
    .prices {
        display: flex;
        gap: 13px;
        align-items: baseline;
    }
    .price {
        font-size: 22px;
        font-weight: 600;
        display: inline-block;
        margin-bottom: 25px;
        @include screen($mobile) {
            font-size: 24px;
        }
    }
    .new_price {
        color: #F2A019;
    }
    .old_price {
        text-decoration: line-through;
        color: #7E8593;
        font-size: 14px;
    }
    .btn {
        width: 100%;
        height: 48px;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        @include screen($mobile_l) {
            max-width: 100%;
            min-width: 0;
        }
        .icon-check {
            display: none;
        }
        &.added {
            background-color: #E9E9E9;
            color: $color_primary_text;
            .icon-check {
                color: $color_primary;
                display: inline-block;
                width: 16px;
                height: 16px;
            }
        }
    }
}