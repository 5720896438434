%burger {
    width: 100%;
    background: #7E8593;
    height: 2px;
    transition: all 0.25s cubic-bezier(0.2, 0, 0.3, 1);
    position: relative;
    
}

.main-menu {
    width: 946px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include screen($tablet_l) {
        position: fixed;
        background: #fff;
        box-shadow: 0px 4px 25px 0px #0000001a;
        width: 100%;
        top: 0;
        right: -100%;
        bottom: 0;
        left: auto;
        margin: 0;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 48px 60px;
        z-index: 3;
        font-size: 28px;
        transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
        
        &.opened {
            right: 0;
        }
    }
    @include screen($tablet) {
        padding: 8px 16px 20px;
    }
    @include screen($mobile) {
        padding-top: 14px;
    }
    .social-list {
        margin-right: 7px;
    }
    .logo {
        @include min-screen($tablet_l + 1px) {
            display: none;
        }
    }
}

.main-menu__btn {
    z-index: 4;
    position: absolute;
    right: 60px;
    top: 75px;
    &::before {
        @extend %ba;
        @extend %burger;
    }

    &::after {
        @extend %ba;
        @extend %burger;
    }

    span {
        @extend %burger;
        transform: scale(1);
    }

    @include min-screen($tablet_l + 1px) {
        display: none;
    }
    @include screen($tablet_l) {
        display: flex;
        order: 3;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 40px;
        height: 24px;
        
        &.main-menu__btn_active {
            position: fixed;
            
        }
        &.main-menu__btn_active:before {
            transform:rotate(45deg);
            top: 11px;
        }
        
        &.main-menu__btn_active:after {
            transform:rotate(-45deg);
            top: -11px;
        }
        
        &.main-menu__btn_active span {
            transform: scale(0);
        }
    }
    @include screen($tablet) {
        right: 19px;
        top: 37px;
    }
    @include screen($mobile) {
        top: 26px;
        width: 24px;
        height: 17px;

        &.main-menu__btn_active:before {
            top: 7px;
        }
        &.main-menu__btn_active:after {
            top: -7px;
        }
    }

}

/* выпадающее, скролл для него*/

.main-menu {
    &__list {
        @extend %scrollbar;
    }
}