.lead-text {
    font-size: 32px;
    text-align: center;
    width: 1080px;
    max-width: 100%;
    margin-inline: auto;
    letter-spacing: -.6px;
    padding-top: 6px;
    line-height: 1.45;
    @include screen($tablet) {
        font-size: 20px;
    }

    b {
        font-weight: 500;
        position: relative;

        &::after {
            @extend %ba;
            position: absolute;
            width: 104%;
            height: 9px;
            left: -2%;
            bottom: -8px;
            background: url(../img/underline2.svg) center no-repeat;
            background-size: cover;

        }
    }
}