/*************************
*Блок
*************************/
.radio:has(.radio__input:not(:disabled):not(:checked)):hover::before{
	border-color: $color_primary;
}
.radio:has(.radio__input:checked)::before {
	border-width:5px;
	border-color: $color_primary;
	// background-color: #0b76ef;
	// background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.radio__input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	width: 0;

	// &+.radio {
	// 	display: inline-flex;
	// 	align-items: center;
	// 	user-select: none;
	// }
	/* стили при наведении курсора на радио */
	
	/* стили для активной радиокнопки (при нажатии на неё) */
	// &:not(:disabled):active+.radio::before {
	// 	background-color: #b3d7ff;
	// 	border-color: #b3d7ff;
	// }
	/* стили для радиокнопки, находящейся в фокусе */
	// &:focus+.radio::before {
	// 	// box-shadow: $shadow;
	// }
	/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
	// &:focus:not(:checked)+.radio::before {
	// 	border-color: #80bdff;
	// }
	/* стили для радиокнопки, находящейся в состоянии checked */

	// /* стили для радиокнопки, находящейся в состоянии disabled */
	// &:disabled+.radio::before {
	// 	background-color: #e9ecef;
	// }

}

/*************************
*Модификаторы блока
*************************/
