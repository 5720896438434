@import 'helpers/_libs'; //vars & mixins & reset 
@import "extends/_beforeAfter.scss";
@import "extends/_scrollbar.scss";
@import "extends/_text.scss";
@import "extends/_x.scss"; //extends
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/about/_about.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/btn/_btn.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cal-calc/_cal-calc.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cards/_card.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cards/_cards-container.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cart/_cart-container.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cart/_cart-container__body.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cart/_cart-products.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/cart/_mini-cart.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/checkbox/_checkbox.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/checkbox/_checkbox__input.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/filters/_filters.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/filters/_promo-code.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/footer/_footer-menu.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/footer/_footer.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/header/_header.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/hero/_hero.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/hero/_hero_reverse.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/icon/_icon.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/img-text/_img-text.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/lead-text/_lead-text.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/logo/_logo.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/main-menu/_main-menu.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/main-menu/_main-menu__item.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/main-menu/_main-menu__list.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/notice/_notice.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/p-card/_p-card.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/p-card/_swiper-product.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/radio/_radio.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/radio/_radio__input.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/radio-group/_radio-group.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/radio-group/_radio-group__controls.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/radio-group/_radio-group__title.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/reviews/_reviews.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/social-list/social-list.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/steps/_steps.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/swiper/_swiper.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/toggle-panel/_toggle-panel.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/toggle-panel/_toggle-panel__content.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/toggle-panel/_toggle-panel__item.scss";
@import "/home/titto/Web/my-gulp/sites/fit_menu2024/dev/blocks/common/toggle-panel/_toggle-panel__title.scss";
@import 'blocks/_common.scss';


body.bg {
	position: relative;

	&:after {
		@extend %ba;
		width: 1440px;
		// width: 768px;
		width: 375px;
		// width: 320px;
		height: 20000px;
		background: url(../img/bgs/bg11.jpg) center 0 no-repeat;
		background: url(../img/bgs/bg12.jpg) center 0 no-repeat;
		// background: url(../img/bgs/bgmain3.jpg) center 0 no-repeat;
		// background: url(../img/bgmenu14.jpg) center 0 no-repeat;
		// background: url(../img/bg-form-3.jpg) center 0 no-repeat;
		background-size: 100%;
		position: absolute;
		opacity: 0.5;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
				// width: 932px;
				// height: 1159px;
				// top: 18px;
				top: -35px;
				// top: 4629px;
	}
}


.faq {
	margin-bottom: 70px;
}
.overlay {
	background-color: #0C203680;
	opacity: .5;
	position: fixed;
	top: 0;left: 0;bottom: 0;right: 0;
	z-index: 1;
	display: none;
	&.opened {
		display: block;
	}
}