
.footer-menu {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-right: auto;
    // height: 100%;
    @include screen($tablet_l) {
        order: 1;
        margin-inline: auto;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    @include screen($tablet_f) {
        flex-direction: column;
        align-items: flex-start;
        margin: 40px 0 30px;
        gap: 24px;
        width: 100%;
    }
    a,li {
        color: #787878;
        text-transform: uppercase;
        transition: color $transition;
    }
    .active, li a:hover {
        color: $color_primary_text;
        text-decoration: none;
    }
    
}

