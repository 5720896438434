/*************************
*Блок
*************************/
.radio {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 18px;
	border-radius: 12px;
	font-size: 14px;
	letter-spacing: 0;
	border: 1px solid #DEDEDE;
	padding: 8px 25px 8px 16px;
	max-width: 100%;
	height: 54px;
	transition: border $transition;
	@include screen($mobile_l) {
		height: 46px;
	}
	&:before {
		@extend %ba;
		width: 24px;
		height: 24px;
		border: 1px solid #BFBFBF;
		border-radius: 100%;
		// margin-right: .5rem;
		@include screen($mobile) {
			width: 20px;
			height: 20px;
		}
	}
	&:has(.radio__input:checked), &:hover {
		border-color: $color_primary;
	}
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
