.cal-calc {
    border-radius: 20px;
    border: 2px solid #dedede;
    padding: 40px;
    @include screen($mobile_l) {
        border: none;
        padding: 0;
    }
    p {
        @include screen($mobile) {
            font-size: 12px;
            color: #767676;
        }
    }
}
.cal-form-container {
    display: flex;
    gap: 98px;
    @include screen($tablet_l) {
        gap: 40px;
    }
    @include screen($mobile_l) {
        gap: 16px;
        flex-direction: column;
    }
}
.cal-form {
    margin-top: 22px;
    width: 648px;
    display: flex;
    flex-direction: column;
    @include screen($tablet_l) {
        width: 500px;
    }
    @include screen($tablet_m) {
        width: 350px;
    }
    @include screen($mobile_l) {
        width: 100%;
    }
    @include screen($mobile) {
        margin-top: 18px;
    }
    fieldset {
        flex-grow: 0;
        display: flex;
        gap: 20px;
        width: 100%;
        min-width: 0;
        margin-bottom: 20px;
        @include screen($mobile) {
            flex-direction: column;
            gap: 18px;
            margin-bottom: 18px;
        }
        label {
            width: calc(33% - 11px);
            @include screen($mobile) {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            input {
                margin-top: 8px;
            }
        }
    }
    label {
        font-size: 14px;
    }
    input {
        width: auto;
    }
    .select {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .btn {
        margin-top: 32px;
        height: 59px;
        @include screen($mobile_l) {
            margin-top: 16px;
        }
        @include screen($mobile) {
            margin-top: 40px;
            height: 49px;
        }
    }
}
.cal-answer {
    width: 290px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    @include screen($mobile_l) {
        margin-top: 16px;
        width: 100%;
    }
    &__header {
        font-size: 24px;
        font-weight: 500;
    }
    &__result {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        @include screen($mobile) {
            align-items: baseline;
            gap: 10px;
        }
        .num {
            font-weight: 900;
            font-size: 56px;
            color: $color_primary;
            // line-height: 48px;
            @include screen($mobile_l) {
               font-size: 48px;
            }
        }
        .ccal {
            font-size: 21px;
            margin-bottom: 14px;
        }
    }
}