.open_filter {
    @include min-screen($mobile_l + 1px) {
        display: none;
    }
    font-size: 12px;
    gap: 8px;
    padding-block: 15px;
}
.filters-container {
    margin-bottom: 34px;
    h2 {
        margin-bottom: 47px;
        @include screen($mobile_l) {
            margin-bottom: 25px;
        }
    }
}

.filter {
    margin-bottom: 26px;
    @include screen($mobile_l) {
        font-size: 14px;
    }
    &__header {
        margin-bottom: 17px;
        display: inline-block;
        @include screen($mobile_l) {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        @include screen($mobile_l) {
            flex-direction: column;
            gap: 2px;
            align-items: flex-start;
        }
        @include screen($mobile) {
            align-items: normal;
        }
        &_radios {
            gap: 15px;
        }
        &_selects {
            gap: 16px;
            select {
                width: 360px;
            }
        }
    }
}
.filters {
    @include screen($mobile_l) {
        position: fixed;
        background: #fff;
        box-shadow: 0px 4px 25px 0px #0000001a;
        width: 100%;
        top: 0;
        right: -100%;
        bottom: 0;
        left: auto;
        margin: 0;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        // flex-direction: column;
        // align-items: flex-start;
        // justify-content: flex-start;
        padding: 48px 60px;
        z-index: 5;
        // font-size: 28px;
        transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
        
        &.opened {
            right: 0;
        }
    }
    @include screen(550px) {
        padding: 16px;
    }
    &__close {
        @include min-screen($mobile_l + 1px) {
            display: none;
        }
        position: absolute;
        right: 19px;
        top: 37px;
        width: 40px;
        height: 24px;
        &::before {
            @extend %ba;
            @extend %burger;
            transform: rotate(45deg);
        }
    
        &::after {
            @extend %ba;
            @extend %burger;
            transform: rotate(-45deg);
            top: -1px;
        }
        @include screen(550px) {
            width: 24px;
            height: 17px;
            top: 16px;
            right: 16px;
        }
    }
    &__header {
        @include min-screen($mobile_l + 1px) {
            display: none;
        }
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 24px;
    }
    &__btns {
        @include min-screen($mobile_l + 1px) {
            display: none;
        }
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .btn {
            padding-block: 18px;
        }
    }
}