/*************************
*Блок
*************************/
.checkbox__input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	width: 0;

	&+.checkbox {
		display: inline-flex;
		align-items: center;
		user-select: none;
	}
	/* стили при наведении курсора на радио */
	&:not(:disabled):not(:checked)+.checkbox:hover::before {
		border-color: $color_primary;
	}
	/* стили для активной радиокнопки (при нажатии на неё) */
	&:not(:disabled):active+.checkbox::before {
		background-color: $color_primary;
		border-color: $color_primary;
	}
	/* стили для радиокнопки, находящейся в фокусе */
	&:focus+.checkbox::before {
		// box-shadow: $shadow;
	}
	/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
	&:focus:not(:checked)+.checkbox::before {
		border-color: $color_primary;
	}
	/* стили для радиокнопки, находящейся в состоянии checked */
	&:checked+.checkbox::before {
		border-color: $color_primary;
		background-color: $color_primary;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
	}
	/* стили для радиокнопки, находящейся в состоянии disabled */
	&:disabled+.checkbox::before {
		background-color: #e9ecef;
	}

}

/*************************
*Модификаторы блока
*************************/
