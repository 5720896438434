.steps {
    h2 {
        @include min-screen($mobile_l + 1px) {
            text-align: center;
        }
    }
    ol {
        display: flex;
        gap: 80px;
        list-style-type: none;
        counter-reset: num;
        width: 1130px;
        max-width: 100%;
        margin-inline:auto;
        margin-top: 42px;
        margin-bottom: 57px;
        @include screen($mobile_l) {
            flex-direction: column;
            gap: 25px;
            margin-top: 28px;
            margin-bottom: 46px;
        }
        li {   
            // width: 50%;
            font-size: 24px;
            display: flex;
            align-items: center;
            gap: 24px;
            @include screen($tablet_m) {
                font-size: 1rem;
            }
            @include screen($mobile_l) {
                gap: 16px;
            }
            &:before {
                content: "0" counter(num) ;
                counter-increment: num;
                display: inline-block;
                background-color: #FEF6E9;
                border-radius: 50%;
                font-size: 54px;
                color: #353634;
                width: 110px;
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                @include screen($tablet_m) {
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                }
            }
        }
    }
}