/*************************
*Блок
*************************/
.radio-group__controls {
	.radio {
		margin-bottom: .5rem;
	}
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
