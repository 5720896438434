.toggle-panel__item {
	margin-bottom: 16px;
	@include screen($tablet) {
		margin-bottom: 10px;
	}

}

.toggle-panel__item_active {
	// & .toggle-panel__title {
	// 	background-color: #b3d7ff;
	// 	color: #fff;

		& .icon {
			transform: rotate(90deg);
			// fill: #fff;
		}
	// }

}
