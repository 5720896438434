/*************************
*Блок
*************************/
.checkbox {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	// color: red;
	max-width: 100%;
	height: 48px;
	font-size: 14px;
	letter-spacing: 0;
	&:before {
		@extend %ba;
		width: 22px;
		height: 22px;
		border: 1px solid #E0E0E0;
		border-radius: 4px;
		transition: all $transition;
		@include screen($mobile) {
			width: 20px;
			height: 20px;
		}
	}
}
/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/
