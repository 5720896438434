.cart-container {

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #DEDEDE;
        padding-bottom: 23px;
        @include screen($mobile_l) {
            padding-bottom: 12px;
        }
    }
    &__header {
        font-size: 28px;
        font-weight: 500;
        @include screen($mobile_l) {
            font-size: 18px;
        }
        .num {
            font-size: 16px;
            @include screen($mobile_l) {
                font-size: 14px;
            }
        }
    }
    &__body {
        a {
            text-decoration: none;
        }
        &.empty {
            font-size: 24px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            text-align: center;
            @include screen($mobile_l) {
                font-size: 18px;
            }
            a {
                text-decoration: none;
            }
            p {
                width: 390px;
                max-width: 90%;
            }
        }
        .add-program {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 7px;
            .icon {
                width: 24px;
                height: 24px;
            }
        }

        .promo-code {
            margin-top: 40px;
            @include screen($mobile_l) {
                margin-top: 35px;
            }
            input {
                width: auto;
                flex: 1;
            }
        }
        .cart-total {
            margin-top: 30px;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: baseline;
            @include screen($mobile_l) {
                flex-direction: column;
                margin-top: 50px;
                gap: 10px;
            }
            &__price {
                display: flex;
                margin-left: auto;
                align-items: baseline;
                gap: 10px;
                @include screen($mobile_l) {
                    margin-left: 0;
                }
            }
            .title {
                color: #757272;
                font-weight: 400;
            }
            .value {
                font-size: 24px;
            }
        }

        h3 {
            font-size: 24px;
            margin-top: 50px;
        }

        .order-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .btn {
                width: 100%;
                height: 52px;
                margin-top: 12px;
                @include screen($mobile_l) {
                    margin-top: 0;
                }
            }
        }
        .policy {
            font-size: 14px;
            margin-top: 15px;
            a {
                text-decoration: underline;
            }
        }
    }
}