@charset "UTF-8";
/* inter-regular - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/inter-v13-cyrillic_latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter-v13-cyrillic_latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/inter-v13-cyrillic_latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/inter-v13-cyrillic_latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active,
textarea:focus, textarea:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --primary-text: #171717;
  --secondary-text: #767676;
  --primary: #7A972D;
  --primary-darked:#5A6F24;
}

/************************************
* Sizez & Spaces
************************************/
/************************************
* Text styles
************************************/
/************************************
* Colors
************************************/
/************************************
* Animation
************************************/
body.bg:after, .radio:before, .main-menu__btn::after, .main-menu__btn::before, .lead-text b::after, .filters__close::after, .filters__close::before, .checkbox:before, .mini-cart_close::after, .mini-cart_close::before {
  content: "";
  display: block;
}

body::-webkit-scrollbar, .main-menu__list::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: transparent;
}
body::-webkit-scrollbar-track, .main-menu__list::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb, .main-menu__list::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  outline: none;
  border-radius: 0.5rem;
  background-clip: content-box;
  border: 0.3rem solid transparent;
}
body::-webkit-scrollbar-corner, .main-menu__list::-webkit-scrollbar-corner {
  background: transparent;
}

.about {
  border: 3px solid var(--primary);
  border-radius: 20px;
  padding: 60px;
  display: flex;
  align-items: center;
  gap: 130px;
  font-size: 16px;
}
@media screen and (max-width: 1280px) {
  .about {
    gap: 50px;
    padding: 30px;
    align-items: flex-start;
  }
}
@media screen and (max-width: 950px) {
  .about {
    flex-direction: column;
    gap: 0;
  }
}
@media screen and (max-width: 468px) {
  .about {
    padding: 22px;
    font-size: 14px;
  }
}
.about__img {
  flex-shrink: 0;
}
@media screen and (max-width: 1280px) {
  .about__img {
    width: calc(50% - 25px);
  }
}
@media screen and (max-width: 950px) {
  .about__img {
    width: fit-content;
    margin-inline: auto;
  }
}
@media screen and (max-width: 950px) {
  .about__text {
    display: contents;
  }
}
.about .mark {
  color: #6D6D6D;
  max-width: 90%;
}
@media screen and (min-width: 1281px) {
  .about .mark {
    width: 400px;
    max-width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .about .mark {
    order: -1;
  }
}
@media screen and (max-width: 468px) {
  .about .mark {
    font-size: 16px;
  }
}
@media screen and (max-width: 950px) {
  .about h2 {
    order: -2;
    max-width: 90%;
  }
}
.about ul {
  margin-top: 30px;
}

/*************************
*Блок
*************************/
.btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 23px 24px;
  background-color: var(--primary);
  color: #fff;
  font-size: 1rem;
  border-radius: 12px;
  min-width: 225px;
  width: fit-content;
  text-decoration: none;
  transition: background 0.2s ease;
}
@media screen and (max-width: 468px) {
  .btn {
    width: 100%;
    padding-block: 18px;
  }
}
.btn:hover {
  text-decoration: none;
  background-color: var(--primary-darked);
}
@media screen and (max-width: 650px) {
  .btn .icon {
    width: 16px;
    height: 16px;
  }
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
.btn_center {
  display: flex;
  margin-inline: auto;
}
.btn_transp {
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 8px;
  color: var(--primary);
}
.btn_transp:hover {
  color: #fff;
}
.btn_grey {
  background-color: #E9E9E9;
  color: var(--primary-text);
}
.btn_grey:hover {
  background-color: #b9b7b7;
}

.cal-calc {
  border-radius: 20px;
  border: 2px solid #dedede;
  padding: 40px;
}
@media screen and (max-width: 650px) {
  .cal-calc {
    border: none;
    padding: 0;
  }
}
@media screen and (max-width: 468px) {
  .cal-calc p {
    font-size: 12px;
    color: #767676;
  }
}

.cal-form-container {
  display: flex;
  gap: 98px;
}
@media screen and (max-width: 1280px) {
  .cal-form-container {
    gap: 40px;
  }
}
@media screen and (max-width: 650px) {
  .cal-form-container {
    gap: 16px;
    flex-direction: column;
  }
}

.cal-form {
  margin-top: 22px;
  width: 648px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1280px) {
  .cal-form {
    width: 500px;
  }
}
@media screen and (max-width: 950px) {
  .cal-form {
    width: 350px;
  }
}
@media screen and (max-width: 650px) {
  .cal-form {
    width: 100%;
  }
}
@media screen and (max-width: 468px) {
  .cal-form {
    margin-top: 18px;
  }
}
.cal-form fieldset {
  flex-grow: 0;
  display: flex;
  gap: 20px;
  width: 100%;
  min-width: 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 468px) {
  .cal-form fieldset {
    flex-direction: column;
    gap: 18px;
    margin-bottom: 18px;
  }
}
.cal-form fieldset label {
  width: calc(33% - 11px);
}
@media screen and (max-width: 468px) {
  .cal-form fieldset label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.cal-form fieldset label input {
  margin-top: 8px;
}
.cal-form label {
  font-size: 14px;
}
.cal-form input {
  width: auto;
}
.cal-form .select {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.cal-form .btn {
  margin-top: 32px;
  height: 59px;
}
@media screen and (max-width: 650px) {
  .cal-form .btn {
    margin-top: 16px;
  }
}
@media screen and (max-width: 468px) {
  .cal-form .btn {
    margin-top: 40px;
    height: 49px;
  }
}

.cal-answer {
  width: 290px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
@media screen and (max-width: 650px) {
  .cal-answer {
    margin-top: 16px;
    width: 100%;
  }
}
.cal-answer__header {
  font-size: 24px;
  font-weight: 500;
}
.cal-answer__result {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}
@media screen and (max-width: 468px) {
  .cal-answer__result {
    align-items: baseline;
    gap: 10px;
  }
}
.cal-answer__result .num {
  font-weight: 900;
  font-size: 56px;
  color: var(--primary);
}
@media screen and (max-width: 650px) {
  .cal-answer__result .num {
    font-size: 48px;
  }
}
.cal-answer__result .ccal {
  font-size: 21px;
  margin-bottom: 14px;
}

.card {
  flex: 1;
  border-radius: 20px;
  font-size: 22px;
  padding: 32px;
  letter-spacing: -0.5px;
}
@media screen and (max-width: 1280px) {
  .card {
    font-size: 18px;
  }
}
@media screen and (max-width: 468px) {
  .card {
    padding: 18px;
  }
}
.card__num {
  background-color: #fff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-bottom: 25px;
}
@media screen and (max-width: 468px) {
  .card__num {
    width: 40px;
    height: 40px;
    font-size: 16px;
    margin-bottom: 14px;
  }
}
.card__text {
  min-height: 130px;
}
@media screen and (max-width: 950px) {
  .card__text {
    min-height: auto;
  }
}
.card__mark {
  font-size: 16px;
  background-color: #fff;
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  width: fit-content;
}
@media screen and (max-width: 468px) {
  .card__mark {
    font-size: 14px;
  }
}

.card_l {
  background-color: #F8F3FE;
  padding: 58px 58px 38px;
  text-align: center;
}
@media screen and (max-width: 468px) {
  .card_l {
    padding: 22px 22px 2px 22px;
    font-size: 16px;
  }
}
.card_l__num {
  font-size: 64px;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: 600;
}
@media screen and (max-width: 950px) {
  .card_l__num {
    font-size: 48px;
  }
}
@media screen and (max-width: 650px) {
  .card_l__num {
    font-size: 32px;
  }
}

@media screen and (min-width: 769px) {
  .cards-container h2 {
    text-align: center;
  }
}

.cards {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.cards .card:nth-child(1) {
  background-color: hsla(349, 71%, 54%, 0.12);
}
.cards .card:nth-child(2) {
  background-color: #FEF6E9;
}
.cards .card:nth-child(3) {
  background-color: #F3F9FE;
}
@media screen and (max-width: 950px) {
  .cards {
    flex-direction: column;
    margin-top: 27px;
    margin-bottom: 8px;
    gap: 8px;
  }
}

.cart-container {
  position: fixed;
  background: #fff;
  width: 557px;
  max-width: 92%;
  top: 0;
  right: -100%;
  bottom: 0;
  left: auto;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 29px 24px 33px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: normal;
  transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
}
@media screen and (max-width: 650px) {
  .cart-container {
    padding: 18px 18px 18px 16px;
  }
}
.cart-container.opened {
  right: 0;
}

.mini-cart_close {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 650px) {
  .mini-cart_close {
    width: 18px;
    height: 18px;
  }
}
.mini-cart_close::before {
  transform: rotate(45deg);
}
.mini-cart_close::after {
  transform: rotate(-45deg);
  top: -1px;
}

.cart-container header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 23px;
}
@media screen and (max-width: 650px) {
  .cart-container header {
    padding-bottom: 12px;
  }
}
.cart-container__header {
  font-size: 28px;
  font-weight: 500;
}
@media screen and (max-width: 650px) {
  .cart-container__header {
    font-size: 18px;
  }
}
.cart-container__header .num {
  font-size: 16px;
}
@media screen and (max-width: 650px) {
  .cart-container__header .num {
    font-size: 14px;
  }
}
.cart-container__body a {
  text-decoration: none;
}
.cart-container__body.empty {
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}
@media screen and (max-width: 650px) {
  .cart-container__body.empty {
    font-size: 18px;
  }
}
.cart-container__body.empty a {
  text-decoration: none;
}
.cart-container__body.empty p {
  width: 390px;
  max-width: 90%;
}
.cart-container__body .add-program {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 7px;
}
.cart-container__body .add-program .icon {
  width: 24px;
  height: 24px;
}
.cart-container__body .promo-code {
  margin-top: 40px;
}
@media screen and (max-width: 650px) {
  .cart-container__body .promo-code {
    margin-top: 35px;
  }
}
.cart-container__body .promo-code input {
  width: auto;
  flex: 1;
}
.cart-container__body .cart-total {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: baseline;
}
@media screen and (max-width: 650px) {
  .cart-container__body .cart-total {
    flex-direction: column;
    margin-top: 50px;
    gap: 10px;
  }
}
.cart-container__body .cart-total__price {
  display: flex;
  margin-left: auto;
  align-items: baseline;
  gap: 10px;
}
@media screen and (max-width: 650px) {
  .cart-container__body .cart-total__price {
    margin-left: 0;
  }
}
.cart-container__body .cart-total .title {
  color: #757272;
  font-weight: 400;
}
.cart-container__body .cart-total .value {
  font-size: 24px;
}
.cart-container__body h3 {
  font-size: 24px;
  margin-top: 50px;
}
.cart-container__body .order-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cart-container__body .order-form .btn {
  width: 100%;
  height: 52px;
  margin-top: 12px;
}
@media screen and (max-width: 650px) {
  .cart-container__body .order-form .btn {
    margin-top: 0;
  }
}
.cart-container__body .policy {
  font-size: 14px;
  margin-top: 15px;
}
.cart-container__body .policy a {
  text-decoration: underline;
}

.cart-products {
  margin-bottom: 23px;
}
.cart-products .item {
  padding-block: 15px;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}
@media screen and (max-width: 468px) {
  .cart-products .item {
    gap: 0 12px;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.cart-products .item__img {
  flex-shrink: 0;
  border: 1px solid #DEDEDE;
  border-radius: 12px;
  overflow: hidden;
}
@media screen and (max-width: 468px) {
  .cart-products .item__img {
    border-radius: 8px;
    width: 48px;
  }
}
.cart-products .item__text {
  flex-shrink: 0;
  flex: 1;
  font-size: 12px;
}
@media screen and (max-width: 468px) {
  .cart-products .item__text {
    flex-basis: calc(100% - 110px);
  }
}
.cart-products .item__title {
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 8px;
}
@media screen and (max-width: 468px) {
  .cart-products .item__title {
    font-size: 14px;
  }
}
.cart-products .item__count {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 60px;
}
@media screen and (max-width: 468px) {
  .cart-products .item__count {
    order: 2;
    margin-left: 60px;
  }
}
.cart-products .item__count button {
  width: 16px;
  height: 16px;
  background: url(../img/minus-round.svg) center no-repeat;
  background-size: contain;
}
.cart-products .item__count button.plus {
  background-image: url(../img/plus-round.svg);
}
.cart-products .item__count input {
  font-weight: 500;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 30px;
  text-align: center;
}
.cart-products .item__price {
  width: 85px;
  text-align: right;
  flex-shrink: 0;
  white-space: nowrap;
  font-size: 16px;
}
@media screen and (max-width: 468px) {
  .cart-products .item__price {
    order: 3;
    margin-left: 8px;
    margin-right: auto;
    text-align: left;
  }
}
.cart-products .item__remove {
  flex-shrink: 0;
  width: 33px;
  text-align: right;
}
.cart-products .item__remove button {
  width: 16px;
  height: 16px;
  background: url(../img/plus-round.svg) center no-repeat;
  background-size: contain;
  transform: rotate(45deg);
}

.mini-cart {
  position: relative;
  margin-right: 37px;
}
@media screen and (max-width: 1280px) {
  .mini-cart {
    margin: 15px 80px 0 0;
  }
}
@media screen and (max-width: 468px) {
  .mini-cart {
    margin: 15px 50px 0 0;
  }
}
.mini-cart .num {
  background-color: var(--primary);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: -3px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 468px) {
  .mini-cart .num {
    font-size: 9px;
    width: 15px;
    height: 15px;
  }
}
.mini-cart .icon {
  display: block;
}
@media screen and (max-width: 468px) {
  .mini-cart .icon {
    width: 32px;
    height: 32px;
  }
}

/*************************
*Блок
*************************/
.checkbox {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 100%;
  height: 48px;
  font-size: 14px;
  letter-spacing: 0;
}
.checkbox:before {
  width: 22px;
  height: 22px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  transition: all 0.2s ease;
}
@media screen and (max-width: 468px) {
  .checkbox:before {
    width: 20px;
    height: 20px;
  }
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
/*************************
*Блок
*************************/
.checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  /* стили при наведении курсора на радио */
  /* стили для активной радиокнопки (при нажатии на неё) */
  /* стили для радиокнопки, находящейся в фокусе */
  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  /* стили для радиокнопки, находящейся в состоянии checked */
  /* стили для радиокнопки, находящейся в состоянии disabled */
}
.checkbox__input + .checkbox {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.checkbox__input:not(:disabled):not(:checked) + .checkbox:hover::before {
  border-color: var(--primary);
}
.checkbox__input:not(:disabled):active + .checkbox::before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.checkbox__input:focus:not(:checked) + .checkbox::before {
  border-color: var(--primary);
}
.checkbox__input:checked + .checkbox::before {
  border-color: var(--primary);
  background-color: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.checkbox__input:disabled + .checkbox::before {
  background-color: #e9ecef;
}

/*************************
*Модификаторы блока
*************************/
.open_filter {
  font-size: 12px;
  gap: 8px;
  padding-block: 15px;
}
@media screen and (min-width: 651px) {
  .open_filter {
    display: none;
  }
}

.filters-container {
  margin-bottom: 34px;
}
.filters-container h2 {
  margin-bottom: 47px;
}
@media screen and (max-width: 650px) {
  .filters-container h2 {
    margin-bottom: 25px;
  }
}

.filter {
  margin-bottom: 26px;
}
@media screen and (max-width: 650px) {
  .filter {
    font-size: 14px;
  }
}
.filter__header {
  margin-bottom: 17px;
  display: inline-block;
}
@media screen and (max-width: 650px) {
  .filter__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.filter__items {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
@media screen and (max-width: 650px) {
  .filter__items {
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
  }
}
@media screen and (max-width: 468px) {
  .filter__items {
    align-items: normal;
  }
}
.filter__items_radios {
  gap: 15px;
}
.filter__items_selects {
  gap: 16px;
}
.filter__items_selects select {
  width: 360px;
}

@media screen and (max-width: 650px) {
  .filters {
    position: fixed;
    background: #fff;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1019607843);
    width: 100%;
    top: 0;
    right: -100%;
    bottom: 0;
    left: auto;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 48px 60px;
    z-index: 5;
    transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
  }
  .filters.opened {
    right: 0;
  }
}
@media screen and (max-width: 550px) {
  .filters {
    padding: 16px;
  }
}
.filters__close {
  position: absolute;
  right: 19px;
  top: 37px;
  width: 40px;
  height: 24px;
}
@media screen and (min-width: 651px) {
  .filters__close {
    display: none;
  }
}
.filters__close::before {
  transform: rotate(45deg);
}
.filters__close::after {
  transform: rotate(-45deg);
  top: -1px;
}
@media screen and (max-width: 550px) {
  .filters__close {
    width: 24px;
    height: 17px;
    top: 16px;
    right: 16px;
  }
}
.filters__header {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 24px;
}
@media screen and (min-width: 651px) {
  .filters__header {
    display: none;
  }
}
.filters__btns {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media screen and (min-width: 651px) {
  .filters__btns {
    display: none;
  }
}
.filters__btns .btn {
  padding-block: 18px;
}

.promo-code {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
}
@media screen and (max-width: 650px) {
  .promo-code {
    margin-top: 24px;
  }
}
.promo-code .btn {
  height: 52px;
  min-width: 188px;
  font-size: 16px;
}
@media screen and (max-width: 468px) {
  .promo-code .btn {
    width: auto;
  }
}
.promo-code input {
  width: 296px;
}

.footer-menu {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: auto;
}
@media screen and (max-width: 1280px) {
  .footer-menu {
    order: 1;
    margin-inline: auto;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 900px) {
  .footer-menu {
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 30px;
    gap: 24px;
    width: 100%;
  }
}
.footer-menu a, .footer-menu li {
  color: #787878;
  text-transform: uppercase;
  transition: color 0.2s ease;
}
.footer-menu .active, .footer-menu li a:hover {
  color: var(--primary-text);
  text-decoration: none;
}

.footer {
  padding: 30px 0;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 900px) {
  .footer {
    padding-top: 6px;
  }
}
.footer a {
  color: #756F6F;
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}
.footer .container {
  display: flex;
  flex-wrap: wrap;
  gap: 53px 130px;
}
@media screen and (max-width: 1300px) {
  .footer .container {
    gap: 53px 80px;
  }
}
@media screen and (max-width: 1280px) {
  .footer .container {
    gap: 16px 40px;
  }
}
.footer footer {
  width: 100%;
  display: flex;
  gap: 64px;
}
@media screen and (max-width: 1280px) {
  .footer footer {
    display: contents;
  }
}
@media screen and (max-width: 1280px) {
  .footer footer .copyright {
    width: 100%;
    font-size: 14px;
  }
}
.footer footer .links {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 1280px) {
  .footer footer .links {
    order: 2;
  }
}
@media screen and (max-width: 900px) {
  .footer footer .links {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    width: 100%;
  }
}
.footer footer .creator {
  margin-left: auto;
}
@media screen and (max-width: 1280px) {
  .footer footer .creator {
    order: 3;
  }
}
@media screen and (max-width: 900px) {
  .footer footer .creator {
    margin-left: 0;
    margin-top: 11px;
  }
}
.footer .logo {
  position: relative;
  top: -12px;
}
@media screen and (max-width: 1280px) {
  .footer .logo {
    margin-right: auto;
  }
}
@media screen and (max-width: 900px) {
  .footer .logo {
    width: 62px;
    height: 57px;
    top: -8px;
  }
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  padding-top: 48px;
  padding-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .header .container {
    padding-top: 8px;
    padding-bottom: 20px;
  }
}

.hero {
  display: flex;
  align-items: center;
  gap: 54px;
  font-size: 22px;
  color: var(--secondary-text);
  margin-bottom: 63px;
}
@media screen and (max-width: 1280px) {
  .hero {
    gap: 32px;
  }
}
@media screen and (max-width: 950px) {
  .hero {
    flex-direction: column;
    gap: 0;
  }
}
@media screen and (max-width: 468px) {
  .hero {
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1281px) {
  .hero__img {
    flex-shrink: 0;
  }
}
@media screen and (max-width: 950px) {
  .hero__img {
    order: 2;
  }
}
@media screen and (max-width: 468px) {
  .hero__img {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1280px) {
  .hero__text {
    width: 50%;
  }
}
@media screen and (max-width: 950px) {
  .hero__text {
    display: contents;
  }
}
.hero p {
  width: 560px;
  max-width: 100%;
}
@media screen and (max-width: 950px) {
  .hero p {
    width: 100%;
  }
}
.hero .btn {
  margin-top: 24px;
}
@media screen and (max-width: 950px) {
  .hero .btn {
    order: 3;
  }
}
@media screen and (max-width: 468px) {
  .hero .btn {
    margin-top: 32px;
  }
}

.hero_reverse {
  margin-bottom: 112px;
}
@media screen and (max-width: 950px) {
  .hero_reverse {
    margin-bottom: 66px;
  }
}
.hero_reverse .hero__text {
  order: -1;
  width: 750px;
  padding-top: 38px;
}
.hero_reverse .hero__text h1 {
  line-height: 1.2;
}
.hero_reverse .hero__img {
  margin-inline: auto;
}
@media screen and (max-width: 468px) {
  .hero_reverse .hero__img {
    width: 230px;
  }
}

.icon {
  width: 48px;
  height: 48px;
  fill: currentColor;
  transition: fill 0.2s ease;
}

.img-text {
  display: flex;
  gap: 100px;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .img-text {
    gap: 40px;
  }
}
@media screen and (max-width: 950px) {
  .img-text {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}
.img-text__img {
  flex-shrink: 0;
}
@media screen and (max-width: 1280px) {
  .img-text__img {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 950px) {
  .img-text__img {
    width: fit-content;
    margin-inline: auto;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 950px) {
  .img-text__text {
    display: contents;
  }
}
.img-text h2 {
  max-width: 90%;
}
@media screen and (min-width: 1281px) {
  .img-text h2 {
    width: 450px;
  }
}
@media screen and (max-width: 950px) {
  .img-text h2 {
    order: -1;
  }
}

.lead-text {
  font-size: 32px;
  text-align: center;
  width: 1080px;
  max-width: 100%;
  margin-inline: auto;
  letter-spacing: -0.6px;
  padding-top: 6px;
  line-height: 1.45;
}
@media screen and (max-width: 768px) {
  .lead-text {
    font-size: 20px;
  }
}
.lead-text b {
  font-weight: 500;
  position: relative;
}
.lead-text b::after {
  position: absolute;
  width: 104%;
  height: 9px;
  left: -2%;
  bottom: -8px;
  background: url(../img/underline2.svg) center no-repeat;
  background-size: cover;
}

/*************************
*Лого с фоном
*************************/
.logo {
  display: inline-block;
  height: 69px;
  width: 75px;
  margin-left: 4px;
  background: url(../img/logo.png) center no-repeat;
  background-size: contain;
  text-indent: -1000px;
  overflow: hidden;
}
@media (min-resolution: 2dppx) {
  .logo {
    background-image: url(../img/logo2x.png);
    background-size: contain;
  }
}
@media screen and (max-width: 468px) {
  .logo {
    width: 39px;
    height: 36px;
  }
}

.main-menu__btn span, .main-menu__btn::after, .main-menu__btn::before, .mini-cart_close::before, .mini-cart_close::after, .filters__close::before, .filters__close::after {
  width: 100%;
  background: #7E8593;
  height: 2px;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.3, 1);
  position: relative;
}

.main-menu {
  width: 946px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1280px) {
  .main-menu {
    position: fixed;
    background: #fff;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1019607843);
    width: 100%;
    top: 0;
    right: -100%;
    bottom: 0;
    left: auto;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 48px 60px;
    z-index: 3;
    font-size: 28px;
    transition: right 0.25s cubic-bezier(0.2, 0, 0.3, 1), width 0s;
  }
  .main-menu.opened {
    right: 0;
  }
}
@media screen and (max-width: 768px) {
  .main-menu {
    padding: 8px 16px 20px;
  }
}
@media screen and (max-width: 468px) {
  .main-menu {
    padding-top: 14px;
  }
}
.main-menu .social-list {
  margin-right: 7px;
}
@media screen and (min-width: 1281px) {
  .main-menu .logo {
    display: none;
  }
}

.main-menu__btn {
  z-index: 4;
  position: absolute;
  right: 60px;
  top: 75px;
}
.main-menu__btn span {
  transform: scale(1);
}
@media screen and (min-width: 1281px) {
  .main-menu__btn {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .main-menu__btn {
    display: flex;
    order: 3;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40px;
    height: 24px;
  }
  .main-menu__btn.main-menu__btn_active {
    position: fixed;
  }
  .main-menu__btn.main-menu__btn_active:before {
    transform: rotate(45deg);
    top: 11px;
  }
  .main-menu__btn.main-menu__btn_active:after {
    transform: rotate(-45deg);
    top: -11px;
  }
  .main-menu__btn.main-menu__btn_active span {
    transform: scale(0);
  }
}
@media screen and (max-width: 768px) {
  .main-menu__btn {
    right: 19px;
    top: 37px;
  }
}
@media screen and (max-width: 468px) {
  .main-menu__btn {
    top: 26px;
    width: 24px;
    height: 17px;
  }
  .main-menu__btn.main-menu__btn_active:before {
    top: 7px;
  }
  .main-menu__btn.main-menu__btn_active:after {
    top: -7px;
  }
}

/* выпадающее, скролл для него*/
.main-menu__item a {
  font-weight: 500;
  text-decoration: none;
  color: #191919;
  transition: color 0.2s ease;
}

.main-menu__item_active > a, .main-menu__item a:hover {
  color: var(--primary);
}

/*************************
*Для выпадающего меню
*************************/
.main-menu__list {
  display: flex;
  align-items: center;
  gap: 31px;
}
@media screen and (max-width: 1280px) {
  .main-menu__list {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 60px;
    margin-bottom: auto;
  }
}

.notice {
  border: 2px solid var(--primary);
  border-radius: 20px;
  font-size: 28px;
  text-align: center;
  padding: 40px 40px 41px;
}
@media screen and (max-width: 950px) {
  .notice {
    font-size: 20px;
  }
}
@media screen and (max-width: 468px) {
  .notice {
    padding: 28px 25px;
  }
}
.notice p {
  width: 900px;
  max-width: 100%;
  margin-inline: auto;
  letter-spacing: -0.5px;
}
@media screen and (max-width: 468px) {
  .notice p {
    max-width: 90%;
  }
}

.p-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 315px;
  border: 1px solid #DCDCDC;
  border-radius: 20px;
  padding: 8px 20px 20px;
}
@media screen and (max-width: 468px) {
  .p-card {
    padding-top: 0;
    border-radius: 8px;
  }
}
.p-card picture {
  width: 100%;
  display: block;
  text-align: center;
}
.p-card .title {
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 16px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .p-card .title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 13px;
  }
}
@media screen and (max-width: 468px) {
  .p-card .title {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.p-card .more {
  font-size: 12px;
  text-decoration: none;
}
.p-card .more:hover {
  text-decoration: underline;
}
.p-card .select {
  font-size: 14px;
  color: rgba(118, 118, 118, 0.5);
  margin-top: auto;
}
.p-card select {
  width: 100%;
  padding: 8px 30px 8px 10px;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  font-size: 14px;
  height: auto;
  color: var(--primary-text);
  margin-top: 9px;
  margin-bottom: 30px;
  background-size: 18px;
  background-position: right 8px center;
}
.p-card .prices {
  display: flex;
  gap: 13px;
  align-items: baseline;
}
.p-card .price {
  font-size: 22px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 25px;
}
@media screen and (max-width: 468px) {
  .p-card .price {
    font-size: 24px;
  }
}
.p-card .new_price {
  color: #F2A019;
}
.p-card .old_price {
  text-decoration: line-through;
  color: #7E8593;
  font-size: 14px;
}
.p-card .btn {
  width: 100%;
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 650px) {
  .p-card .btn {
    max-width: 100%;
    min-width: 0;
  }
}
.p-card .btn .icon-check {
  display: none;
}
.p-card .btn.added {
  background-color: #E9E9E9;
  color: var(--primary-text);
}
.p-card .btn.added .icon-check {
  color: var(--primary);
  display: inline-block;
  width: 16px;
  height: 16px;
}

.swiper-product {
  position: relative;
}
@media screen and (max-width: 768px) {
  .swiper-product .sw-pagination {
    display: none;
  }
}

/*************************
*Блок
*************************/
.radio {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
  font-size: 14px;
  letter-spacing: 0;
  border: 1px solid #DEDEDE;
  padding: 8px 25px 8px 16px;
  max-width: 100%;
  height: 54px;
  transition: border 0.2s ease;
}
@media screen and (max-width: 650px) {
  .radio {
    height: 46px;
  }
}
.radio:before {
  width: 24px;
  height: 24px;
  border: 1px solid #BFBFBF;
  border-radius: 100%;
}
@media screen and (max-width: 468px) {
  .radio:before {
    width: 20px;
    height: 20px;
  }
}
.radio:has(.radio__input:checked), .radio:hover {
  border-color: var(--primary);
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
/*************************
*Блок
*************************/
.radio:has(.radio__input:not(:disabled):not(:checked)):hover::before {
  border-color: var(--primary);
}

.radio:has(.radio__input:checked)::before {
  border-width: 5px;
  border-color: var(--primary);
}

.radio__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  /* стили при наведении курсора на радио */
  /* стили для активной радиокнопки (при нажатии на неё) */
  /* стили для радиокнопки, находящейся в фокусе */
  /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
  /* стили для радиокнопки, находящейся в состоянии checked */
}

/*************************
*Модификаторы блока
*************************/
/*************************
*Блок
*************************/
.radio-group {
  display: flex;
  flex-direction: column;
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
/*************************
*Блок
*************************/
.radio-group__controls .radio {
  margin-bottom: 0.5rem;
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
/*************************
*Блок
*************************/
.radio-group__title {
  font-size: 2rem;
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
.reviews {
  position: relative;
}
.reviews .item {
  flex-shrink: 0;
}
.reviews .sw-pagination {
  position: absolute;
  width: 108px;
  right: 2px;
  top: -6px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 650px) {
  .reviews .sw-pagination {
    width: 90px;
  }
}
.reviews .sw-pagination .sw-prev, .reviews .sw-pagination .sw-next {
  position: initial;
  width: 48px;
  height: 48px;
  border: 1px solid #676767;
  background-size: 12px;
}
@media screen and (max-width: 650px) {
  .reviews .sw-pagination .sw-prev, .reviews .sw-pagination .sw-next {
    width: 40px;
    height: 40px;
  }
}
.reviews .swiper {
  margin-top: 45px;
}

/*************************
*Блок
*************************/
.social-list {
  display: flex;
  gap: 15px;
}
@media screen and (max-width: 650px) {
  .social-list {
    gap: 10px;
  }
}

/*************************
*Элементы
*************************/
/*************************
*Модификации
*************************/
@media screen and (min-width: 651px) {
  .steps h2 {
    text-align: center;
  }
}
.steps ol {
  display: flex;
  gap: 80px;
  list-style-type: none;
  counter-reset: num;
  width: 1130px;
  max-width: 100%;
  margin-inline: auto;
  margin-top: 42px;
  margin-bottom: 57px;
}
@media screen and (max-width: 650px) {
  .steps ol {
    flex-direction: column;
    gap: 25px;
    margin-top: 28px;
    margin-bottom: 46px;
  }
}
.steps ol li {
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
@media screen and (max-width: 950px) {
  .steps ol li {
    font-size: 1rem;
  }
}
@media screen and (max-width: 650px) {
  .steps ol li {
    gap: 16px;
  }
}
.steps ol li:before {
  content: "0" counter(num);
  counter-increment: num;
  display: inline-block;
  background-color: #FEF6E9;
  border-radius: 50%;
  font-size: 54px;
  color: #353634;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media screen and (max-width: 950px) {
  .steps ol li:before {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }
}

.sw-pagination .sw-prev, .sw-pagination .sw-next {
  width: 64px;
  height: 64px;
  background: #fff url(../img/arrow.svg) center no-repeat;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.0392156863);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 64px);
  left: -32px;
  z-index: 1;
}
.sw-pagination .sw-next {
  transform: rotate(180deg);
  left: auto;
  right: -32px;
}
.sw-pagination .swiper-button-disabled {
  opacity: 0.5;
}

.toggle-panel {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .toggle-panel {
    margin-top: 24px;
  }
}

.toggle-panel__content {
  overflow: hidden;
  height: 0;
  transition: all 0.2s ease;
}
.toggle-panel__content .inner {
  padding: 0.5rem;
}

.toggle-panel__item {
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .toggle-panel__item {
    margin-bottom: 10px;
  }
}

.toggle-panel__item_active .icon {
  transform: rotate(90deg);
}

/*************************
*Блок с анимацией
*************************/
.toggle-panel__title {
  font-weight: 500;
  color: #000;
  padding: 21px 79px 21px 35px;
  letter-spacing: -0.35px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F4FAE5;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .toggle-panel__title {
    padding: 18px 55px 18px 17px;
    border-radius: 12px;
  }
}
.toggle-panel__title .icon {
  background-color: var(--primary);
  border-radius: 100%;
  transition: all 0.2s ease;
  width: 48px;
  height: 48px;
  color: #fff;
  position: relative;
  right: -48px;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .toggle-panel__title .icon {
    width: 32px;
    height: 32px;
    right: -40px;
  }
}
.toggle-panel__title:hover {
  background-color: #e5f4be;
}

/*************************
*Элементы
*************************/
/*************************
*Модификаторы блока
*************************/
html {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.21;
  color: var(--primary-text);
  letter-spacing: -0.2px;
  background-color: #fff;
}
body.no-scroll {
  overflow: hidden;
}

a {
  color: var(--primary);
}

p {
  margin-bottom: 0.89em;
}

h1 {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: var(--primary-text);
  letter-spacing: -0.8px;
  line-height: 1.45;
}
@media screen and (max-width: 468px) {
  h1 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
}

h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 18px;
  letter-spacing: -0.8px;
}
@media screen and (max-width: 468px) {
  h2 {
    font-size: 25px;
  }
}

h3 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 18px;
}

h1 b, h2 b, h3 b {
  color: var(--primary);
  font-weight: inherit;
}

.container {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .container {
    padding-inline: 16px;
  }
}

label.error {
  color: red;
}
label.error input {
  border-color: red;
}

.section {
  margin-bottom: 120px;
}
@media screen and (max-width: 650px) {
  .section {
    margin-bottom: 64px;
  }
}

input, textarea, select {
  max-width: 100%;
  padding: 15px 16px;
  border: 1px solid #f8f8f8;
  border-radius: 8px;
  color: var(--primary-text);
  background-color: #F8F8F8;
  font-size: 16px;
  letter-spacing: 0;
}
input::placeholder, textarea::placeholder, select::placeholder {
  color: #7E8593;
}
input:focus, input:focus-visible, textarea:focus, textarea:focus-visible, select:focus, select:focus-visible {
  border-color: var(--primary);
  outline: none;
}

select {
  border-radius: 8px;
  border: 1px solid #f8f8f8;
  height: 52px;
  color: var(--secondary-text);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../img/chevron-down.svg);
  background-position: right 15px center;
  background-repeat: no-repeat;
  padding-right: 40px;
}

ul:not([class]) {
  list-style-type: none;
  margin-bottom: 12px;
}
ul:not([class]) li {
  position: relative;
  padding: 0 0 1em 1.5em;
}
ul:not([class]) li:before {
  content: "";
  display: block;
  position: absolute;
  top: 0.5em;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: currentColor;
}

body.bg {
  position: relative;
}
body.bg:after {
  width: 1440px;
  width: 375px;
  height: 20000px;
  background: url(../img/bgs/bg11.jpg) center 0 no-repeat;
  background: url(../img/bgs/bg12.jpg) center 0 no-repeat;
  background-size: 100%;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  top: -35px;
}

.faq {
  margin-bottom: 70px;
}

.overlay {
  background-color: rgba(12, 32, 54, 0.5019607843);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: none;
}
.overlay.opened {
  display: block;
}