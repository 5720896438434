.notice {
    border: 2px solid $color_primary;
    border-radius: 20px;
    font-size: 28px;
    text-align: center;
    padding: 40px 40px 41px;
    @include screen($tablet_m) {
        font-size: 20px;
    }
    @include screen($mobile) {
        padding: 28px 25px;
    }
    p {
        width: 900px;
        max-width: 100%;
        margin-inline: auto;
        letter-spacing: -.5px;
        @include screen($mobile) {
           max-width: 90%;
        }
    }
}