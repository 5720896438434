/*************************
*Блок
*************************/
.social-list {
    display: flex;
    gap: 15px;
    @include screen($mobile_l) {
        gap: 10px;
    }
}
/*************************
*Элементы
*************************/

/*************************
*Модификации
*************************/
