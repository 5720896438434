/*************************
*Блок с анимацией
*************************/
.toggle-panel__title {
	font-weight: 500;
	color: #000;
	padding:21px 79px 21px 35px;
	letter-spacing: -.35px;
	cursor: pointer;
	transition: all $transition;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F4FAE5;
	border-radius: 20px;
	@include screen($tablet) {
		padding: 18px 55px 18px 17px;
		border-radius: 12px;
	}

	& .icon {
		background-color: $color_primary;
		border-radius: 100%;
		transition: all $transition;
		width: 48px;
		height: 48px;
		color: #fff;
		position: relative;
    	right: -48px;
		flex-shrink: 0;
		@include screen($tablet) {
			width: 32px;
			height: 32px;
			right: -40px;
		}
	}

	&:hover {
		background-color: #e5f4be;
		// color: #fff;

	}

}


/*************************
*Элементы
*************************/

/*************************
*Модификаторы блока
*************************/