.img-text {
    display: flex;
    gap: 100px;
    align-items: center;
    @include screen($tablet_l) {
        gap: 40px;
    }
    @include screen($tablet_m) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
    }
    &__img {
        flex-shrink: 0;
        @include screen($tablet_l) {
            width: calc(50% - 20px);
        }
        @include screen($tablet_m) {
            width: fit-content;
            margin-inline: auto;
            margin-bottom: 20px;
        }
    }
    &__text {
        @include screen($tablet_m) {
            display: contents;
        }
    }
    h2 {
        max-width: 90%;
        @include min-screen($tablet_l + 1px) {
            width: 450px;
        }
        @include screen($tablet_m) {
            order: -1;
        }
       
    }
}