.card {
    flex: 1;
    border-radius: 20px;
    font-size: 22px;
    padding: 32px;
    letter-spacing: -.5px;
    @include screen($tablet_l) {
        font-size: 18px;
    }
    @include screen($mobile) {
        padding: 18px;
    }

    
    &__num {
        background-color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        margin-bottom: 25px;
        @include screen($mobile) {
            width: 40px;
            height: 40px;
            font-size: 16px;
            margin-bottom: 14px;
        }
    }
    &__text {
        min-height: 130px;
        @include screen($tablet_m) {
            min-height: auto;
        }
    }
    &__mark {
        font-size: 16px;
        background-color: #fff;
        padding: 10px 16px 10px 16px;
        border-radius: 12px;
        width: fit-content;
        @include screen($mobile) {
            font-size: 14px;
        }
    }
}
.card_l {
    background-color: #F8F3FE;
    padding: 58px 58px 38px;
    text-align: center;
    @include screen($mobile) {
        padding: 22px 22px 2px 22px;
        font-size: 16px;
    }
    &__num {
        font-size: 64px;
        line-height: 1;
        margin-bottom: 15px;
        font-weight: 600;
        @include screen($tablet_m) {
            font-size: 48px;
        }
        @include screen($mobile_l) {
            font-size: 32px;
        }

    }
}