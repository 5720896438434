.sw-pagination {
    // background-color: red;
    // position: relative;
    .sw-prev, .sw-next {
        width: 64px;
        height: 64px;
        background: #fff url(../img/arrow.svg) center no-repeat;
        box-shadow: 0px 4px 25px 0px #0000000A;
        border-radius: 50%;
        cursor: pointer;

        position: absolute;
        top: calc(50% - 64px);
        left: -32px;
        z-index: 1;

    }
    .sw-next {
        transform: rotate(180deg);
        left: auto;
        right: -32px;
    }
    .swiper-button-disabled {
        opacity: .5;
    }
}