.main-menu__list {
    display: flex;
    align-items: center;
    gap: 31px;

     @include screen($tablet_l) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
        margin-bottom: auto;
    }
}