.main-menu__item {
    // position: relative;
    // font-size: 2rem;
    // flex: 1;

    a {
        font-weight: 500;
        text-decoration: none;
        color: #191919;
        // padding: 1rem 2rem;
        // display: flex;
        // justify-content: center;
        transition: color $transition
    }

}

.main-menu__item_active>a, .main-menu__item a:hover {
    color: $color_primary;
}


/*************************
*Для выпадающего меню
*************************/

// @include min-screen(1001px) {
//     .main-menu__sub-list {
//         position: absolute;
//         top: 130%;
//         opacity: 0;
//         z-index: -1;
//         transition: all $transition;
//         pointer-events: none;
//         width: 100%;
//     }

//     .main-menu__item {
//         &:hover {
//             .main-menu__sub-list {
//                 opacity: 1;
//                 top: 100%;
//                 z-index: 1;
//                 pointer-events: auto;
//             }
//         }
//     }
// }
// @include screen(1000px) {
//     .main-menu__sub-list {
//         height: 0;
//         overflow:hidden;
//         transition: all $transition
//     }
//     .main-menu__item {
//         flex: initial;
//         margin-bottom: 2rem;
//         & ul a {
//             background:none;
//             padding: .5rem;
//             font-size: 1rem;
//             justify-content: right;
//         }
//         & ul .main-menu__item_active a {
//             background:#80bdff;
//         }
//     }
//     .main-menu__sub-list {
//         & .main-menu__item {
//             margin-bottom: .5rem;
//         }
//     }
// }
