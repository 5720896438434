.hero {
    display: flex;
    align-items: center;
    gap: 54px;
    font-size: 22px;
    color: $color_secondary_text;
    margin-bottom: 63px;
    @include screen($tablet_l) {
        gap: 32px;
    }
    @include screen($tablet_m) {
        flex-direction: column;
        gap: 0;
    }
    @include screen($mobile) {
        font-size: 12px;
        line-height: 18px;
    }
    &__img {
        @include min-screen($tablet_l + 1px) {
            flex-shrink: 0;
        }
        @include screen($tablet_m) {
            order: 2;
        }
        @include screen($mobile) {
            margin-top: 10px;
        }
    }
    &__text {
        @include screen($tablet_l) {
            width: 50%;
        }
        @include screen($tablet_m) {
            display: contents;
        }
    }
    p {
        width: 560px;
        max-width: 100%;
        @include screen($tablet_m) {
            width: 100%;
        }
    }
    .btn {
        margin-top: 24px;
        @include screen($tablet_m) {
            order: 3;
        }
        @include screen($mobile) {
            margin-top: 32px;
        }
    }
}