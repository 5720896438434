
.footer {
    // background-color: #000;
    padding: 30px 0;
    font-size: 16px;
    font-weight: 500;
    @include screen($tablet_f) {
        padding-top: 6px;
    }

    a {
        color: #756F6F;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        gap: 53px 130px;
        @include screen(1300px) {
            gap: 53px 80px;
        }
        @include screen($tablet_l) {
            gap: 16px 40px;
        }
    }

    footer {
        width: 100%;
        display: flex;
        gap: 64px;
        @include screen($tablet_l) {
            display: contents;
        }
        .copyright {
            @include screen($tablet_l) {
                width: 100%;
                font-size: 14px;
            }
        }
        .links {
            display: flex;
            gap: 20px;
            @include screen($tablet_l) {
               order: 2;
            }
            @include screen($tablet_f) {
               flex-direction: column;
               gap: 30px;
               align-items: flex-start;
               width: 100%;
            }
        }
        .creator {
            margin-left: auto;
            @include screen($tablet_l) {
                order: 3;
            }
            @include screen($tablet_f) {
                margin-left: 0;
                margin-top: 11px;
            }
        }
    }
    .logo {
        position: relative;
        top: -12px;
        @include screen($tablet_l) {
            margin-right: auto;
        }
        @include screen($tablet_f) {
            width: 62px;
            height: 57px;
            top: -8px;
        }
    }
}

